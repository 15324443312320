.container {
    display: flex; /* Enable Flexbox for horizontal alignment */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Align items vertically */
    gap: 60px; /* Space between text and image */
    padding-top: 300px;
    padding-bottom: 300px;
     /* Adjust padding for better responsiveness */
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    position: relative; /* Enable positioning for the button */
}

.text-container {
    text-align: right; /* Align text to the right */
    padding: 20px; /* Adjust padding for better responsiveness */
    border-right: 2px solid #d4a200; /* Add a vertical line */
    margin-bottom: 20px; /* Space below text-container on wrap */
    max-width: 500px; /* Limit the width for readability */
    flex: 1 1 300px; /* Allow text to shrink and grow flexibly */
}

.portrait {
    width: 150px; /* Adjust size for smaller screens */
    height: 150px; /* Keep the circular shape */
    border-radius: 50%; /* Circular image */
    object-fit: cover; /* Ensure the image fills the circle without distortion */
    object-position: center bottom; /* Focus image lower */
    transform: scale(1.1); /* Subtle zoom for better proportions */
    border: 5px solid #e9f7f6; /* Optional: Add a border */
    background-color: #e9f7f6;
    flex-shrink: 0; /* Prevent image from shrinking */
}

.highlight {
    color: #d4a200; /* Highlighted text color */
    font-weight: bold; /* Bold text */
}

.lets-connect {
    display: inline-block;
    background-color: #e9f7f6; /* Button background color */
    color: #0d0d0d; /* Button text color */
    text-decoration: none; /* Remove underline */
    padding: 10px 20px; /* Add padding inside the button */
    font-size: 16px; /* Adjust font size */
    font-weight: bold;
    border-radius: 30px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor for hover */
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.lets-connect:hover {
    background-color: #e4c553; /* Slightly brighter hover color */
    transform: translateY(-2px); /* Lift the button on hover */
}


.hr-home {
    width: 90%; /* Adjust width for responsiveness */
    margin: 20px auto; /* Center with margin */
    border: none;
    height: 2px;
    background: linear-gradient(to right, transparent, #e9f7f6, transparent);
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .container {
        flex-direction: column; /* Stack items vertically */
        gap: 20px; /* Reduce spacing between elements */
        padding: 30px 10px; /* Reduce padding for smaller screens */
    }

    .text-container {
        text-align: center; /* Center-align text for better appearance */
        border-right: none; /* Remove border for smaller screens */
        border-bottom: 2px solid #d4a200; /* Optional: Add bottom border */
        padding-bottom: 20px;
    }

    .portrait {
        width: 120px; /* Reduce image size for smaller screens */
        height: 120px; /* Keep circular shape */
    }

    .lets-connect {
        font-size: 14px; /* Smaller font size for button */
        padding: 8px 15px; /* Reduce button padding */
    }

    .hr-home {
        width: 70%; /* Narrower line for smaller screens */
    }
}
