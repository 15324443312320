/* Basic Reset */
body, h1, a, button {
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-family: 'Georgia', serif;
    color: #0d0d0d; /* Dark navy or black color */
}

/* Header Styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    font-size: 2.5rem;
    font-weight: bold;
    color: #0d0d0d; /* Navy */
}

.divider {
    height: 2rem;
    width: 2px;
    background-color: #e4c553; /* Gold */
    margin: 0 10px;
}

.name {
    font-size: 1.5rem;
    color: #0d0d0d;
}

.nav-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-link {
    font-size: 1rem;
    color: #0d0d0d;
    text-transform: lowercase;
    margin: 0 5px;
    background: none; /* Remove default button styles */
    border: none;
    cursor: pointer;
}

.contact-link {
    background-color: #e9f7f6;
    padding: 5px 15px;
    border-radius: 15px;
}

hr {
    width: 50%;
    margin: 0 auto;
    border: none;
    height: 2px;
    background: linear-gradient(to right, transparent, #e9f7f6, transparent);
}

/* Hover effect for navigation links */
.nav-link:hover {
    color: #e4c553; /* Gold color on hover */
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

/* Hover effect for contact button */
.contact-link:hover {
    background-color: #e4c553; /* Change to gold on hover */
    color: #0d0d0d; /* Contrast dark text */
    transform: scale(1.05); /* Slightly enlarge on hover */
    transition: background-color 0.3s ease, transform 0.2s ease;
}


/* Responsive Navigation */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        padding: 20px;
    }

    .nav-container {
        flex-direction: column; /* Stack navigation links vertically */
        align-items: center; /* Align links to the left */
        gap: 5px; /* Reduce gap for stacked links */
    }

    .nav-link {
        font-size: 1rem;
        margin: 5px 0; /* Add vertical spacing for links */
    }

    .contact-link {
        align-self: flex-start; /* Align the "contact me" button to the left */
    }
}
