/* Bio Container */
.bio-container {
    display: flex; /* Use Flexbox for horizontal alignment */
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Align items vertically */
    gap: 30px; /* Add spacing between braces and content */
    padding: 50px 50px; /* Add padding around the content */
    background-color: #0d0d0d; /* Dark navy background */
    color: #e9f7f6; /* Light text for readability */
}

/* Braces */
.brace {
    font-size: 10rem;
    font-weight: bold;
    color: #e4c553; /* Gold for braces */
    display: inline-block; /* Ensures transform works correctly */
    transform: scaleY(3); /* Stretches the text vertically */
}

/* Bio Section */
.bio-section {
    background-color: #e9f7f6; /* Light greenish-blue for consistent card background */
    padding: 30px;
    border-radius: 15px; /* Rounded corners for content area */
    max-width: 600px; /* Limit content width for better readability */
    flex: 1; /* Allow the bio section to take available space */
    text-align: left;
}

.quote {
    text-align: left; /* Align text to the left */
}

.bio-text {
    font-size: 1.2rem;
    margin-bottom: 15px;
}

.bio-subtext {
    font-size: 1rem;
    color: #0d0d0d; /* Darker text for better contrast */
}

.highlight {
    font-weight: bold;
    color: #e4c553; /* Gold for highlighted text */
}

/* Experience Section */
.experience-section {
    margin-top: 50px; /* Add spacing from the bio section */
    width: 100%;
    max-width: 800px; /* Limit the width of the experience section */
    text-align: left;
}

/* Experience Title */
.experience-title {
    font-size: 2.5rem; /* Adjust font size */
    color: #e9f7f6; /* Title color */
    text-align: left; /* Center the title */
    margin: 0; /* Remove default margins */
    width: 100%; /* Ensure title takes the full width */
    max-width: 900px; /* Align with project card's max width */
    padding: 40px 0; /* Add vertical padding for spacing */
}

/* Individual Experience Card */
.experience-card {
    background-color: #e9f7f6; /* Light greenish-blue for consistent card background */
    color: #0d0d0d; /* Dark text for readability */
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.experience-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.company {
    font-size: 1rem;
    color: #666;
    margin-bottom: 5px;
}

.date {
    font-size: 0.9rem;
    font-style: italic;
    margin-bottom: 10px;
}

.experience-card ul {
    list-style: none;
    padding: 0;
}

.experience-card ul li {
    font-size: 0.9rem;
    margin-bottom: 10px;
    color: #0d0d0d;
}

.button-container {
    margin-top: 20px; /* Add spacing below the bio section */
    text-align: center;
}

/* Resume Button */
.resume-button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #e4c553; /* Gold color for the button */
    color: #0d0d0d; /* Dark text for contrast */
    border: none; /* Remove borders */
    border-radius: 50px; /* Rounded button */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: auto; /* Default width for larger screens */
    max-width: 200px; /* Limit width on larger screens */
    text-align: center; /* Center the text inside the button */
    display: inline-block; /* Ensure proper alignment */
}

/* Hover Effect */
.resume-button:hover {
    background-color: #e9f7f6; /* Lighter hover background */
    transform: translateY(-2px); /* Slight lift effect */
}

/* Centering Button Inside Container */
.button-container {
    margin-top: 20px; /* Spacing from the content above */
    display: flex;
    justify-content: center; /* Center the button horizontally */
    align-items: center; /* Center vertically if needed */
}

/* Responsive Adjustments for Small Screens */
@media (max-width: 600px) {
    .bio-container {
        flex-direction: column;
        align-items: center;
        padding: 15px;
        gap: 20px;
    }

    .brace {
        font-size: 3rem;
        transform: scaleY(1.5);
    }

    .bio-section {
        max-width: 100%;
        padding: 20px;
        text-align: center;
    }

    .bio-text {
        font-size: 1rem;
    }

    .bio-subtext {
        font-size: 0.9rem;
        margin-bottom: 10px;
    }

    .highlight {
        font-size: 1rem;
    }

    .resume-button {
        padding: 8px 12px;
        font-size: 0.9rem;
        max-width: 90%;
    }

    .button-container {
        margin: 20px 0;
        width: 100%;
    }

    .experience-section {
        max-width: 100%;
        margin-top: 30px;
        padding: 10px;
    }

    .experience-title {
        font-size: 1.8rem;
        margin-bottom: 20px;
        text-align: center;
    }

    .experience-card {
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .experience-card h3 {
        font-size: 1.3rem;
        margin-bottom: 5px;
    }

    .company {
        font-size: 0.85rem;
        margin-bottom: 5px;
    }

    .date {
        font-size: 0.8rem;
        margin-bottom: 10px;
    }

    .experience-card ul li {
        font-size: 0.85rem;
    }
}
